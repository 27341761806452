
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      result: "",
      total: [0, 1],
      select: 0,
      show_input: true,
      show_output: false,
      one_line_one: false,
      num_per_line: 5,
      result_arr: [],
      total_max: 1000,
      start_button_msg: "生成随机数",
      download_page_stat: false,
      title_msg: "随机数生成器",
      update_msg_log: `更新日志：
2022-10-12 客户端悬浮窗支持悬停功能,修复手感,减小误触 技术线:TypeScript+Vue+Electron 版本:0.1.5
2022-10-7 桌面客户端新增右键菜单和放大显示功能,新增一键复制功能,修复了一些bug 技术线:TypeScript+Vue+Electron 版本:0.1.4
2022-10-7 正式发布0.1.3版本 技术线:TypeScript+Vue+Electron
2022-10-6 桌面客户端悬浮窗新增右键菜单 技术线:TypeScript+Vue+Electron 版本:0.1.3
2022-10-6 修复桌面客户端悬浮窗不能移动等问题,推出新图标 技术线:TypeScript+Vue+Electron 版本:0.1.3 bate 2
2022-10-6 桌面客户端推出悬浮窗功能,优化ui问题 技术线:TypeScript+Vue+Electron 版本:0.1.3 bate 1
          官方网站新增更新日志
2022-10-5 推出桌面客户端图标,优化ui问题 技术线:TypeScript+Vue+Electron 版本:0.1.2
          推出网页版体验网站random-number.maohaoji.com 技术线:TypeScript+Vue
          随机数生成器random-number正式上线
2022-10-4 推出桌面客户端 技术线:TypeScript+Vue+Electron 版本:0.1.0
2022-10-3 网页版增加输出结果md5值功能功能 技术线:TypeScript+Vue 版本:0.0.5
2022-10-3 网页版增加输出信息功能 技术线:TypeScript+Vue 版本:0.0.4
2022-10-3 网页版增加多行显示、设置一行显示数目功能 技术线:TypeScript+Vue 版本:0.0.3
2022-10-2 网页版添加范围取值功能，新增底部版权声明 技术线:TypeScript+Vue 版本:0.0.2
2022-10-1 网页版逻辑制作完成 技术线:TypeScript+Vue 版本:0.0.1`,
    };
  },
  methods: {
    click() {
      if (this.select == 0) {
        (this as any).$notify({
          title: "出错了",
          message: "要抽取人数不可以为0哦",
          type: "error",
          position: "top-left",
        });
      } else {
        this.show_input = false;
        this.show_output = true;
        this.result = "";
        this.start_button_msg = "重新生成随机数";

        let total_arr: number[] = [];
        for (let i = this.total[0]; i <= this.total[1]; i++) {
          total_arr[total_arr.length] = i;
        }
        total_arr.sort(() => {
          return 0.5 - Math.random();
        });

        (this.result_arr as number[]) = total_arr.slice(0, this.select);
      }
    },
    format(value: number) {
      return `${value}`;
    },
    clear() {
      this.result = "";
      this.total = [0, 1];
      this.select = 0;
      this.show_input = true;
      this.show_output = false;
      this.num_per_line = 5;
      this.start_button_msg = "生成随机数";
    },
    output(
      output: number[],
      num_per_line: number,
      one_line_one: boolean
    ): Promise<string> {
      return new Promise((resolve, reject) => {
        if (output.length == 0 || num_per_line == 0) reject({});
        else {
          let result = "";
          if (one_line_one) {
            for (let i = 0; i < output.length; i++) {
              result += output[i] + "\n";
            }
            resolve(result);
          } else {
            let line: number = Math.floor(output.length / num_per_line) + 1;

            for (let line_num = 0; line_num < line; line_num++) {
              for (let i = 0; i < this.num_per_line; i++) {
                if (line_num * this.num_per_line + i < output.length) {
                  result += output[line_num * this.num_per_line + i] + " ";
                }
              }
              result += "\n";
            }
            resolve(result);
          }
        }
      });
    },
    check() {
      this.output(this.result_arr, this.num_per_line, this.one_line_one)
        .catch(() => {
          return;
        })
        .then((res) => {
          this.result = String(res);
        });
    },
    checknum(type: "total" | "select" | "num_per_line") {
      if (type == "total") {
        if (
          this.total[0] != Math.floor(this.total[0]) ||
          this.total[1] != Math.floor(this.total[1])
        ) {
          this.total[0] = Math.floor(this.total[0]);
          this.total[1] = Math.floor(this.total[1]);
        }
        if (
          typeof this.total[0] != "number" ||
          typeof this.total[1] != "number"
        ) {
          this.total[0] = Number(this.total[0]);
          this.total[1] = Number(this.total[1]);
        }
      } else if (type == "select") {
        if (this.select != Math.floor(this.select)) {
          this.select = Math.floor(this.select);
        }
        if (typeof this.select != "number") {
          this.select = Number(this.select);
        }
      } else if (type == "num_per_line") {
        if (this.num_per_line != Math.floor(this.num_per_line)) {
          this.num_per_line = Math.floor(this.num_per_line);
        }
        if (typeof this.num_per_line != "number") {
          this.num_per_line = Number(this.num_per_line);
        }
        if (this.num_per_line == 0) {
          this.num_per_line = 1;
        }
        this.check();
      }
    },
    advanced_options() {
      (this as any).$notify({
        title: "更多精彩内容请下载客户端",
        message: "请点击“下载客户端”按钮下载客户端来体验更多精彩内容",
        type: "success",
        position: "top-left",
      });
    },
    download_page() {
      this.download_page_stat = !this.download_page_stat;
      if (this.download_page_stat) this.title_msg = "下载随机数生成器";
      else this.title_msg = "随机数生成器";
    },
    Download_win(id: "x64" | "ia32" | "arm64" | "all") {
      switch (id) {
        case "x64": {
          window.location.href = "./setup/随机数生成器_0.1.5_win_x64.exe";
          break;
        }
        case "ia32": {
          window.location.href = "./setup/随机数生成器_0.1.5_win_ia32.exe";
          break;
        }
        case "arm64": {
          window.location.href = "./setup/随机数生成器_0.1.5_win_arm64.exe";
          break;
        }
        case "all": {
          window.location.href = "./setup/随机数生成器_0.1.5_win.exe";
          break;
        }
      }
    },
  },
});
